<div class="error-container">
  <h1>Error</h1>
  <p *ngIf="errorMessage; else defaultMessage">
    {{ errorMessage }}
  </p>
  <ng-template #defaultMessage>
    <p>Something went wrong! Please try again.</p>
  </ng-template>
  <a routerLink="/">Go Back to Home</a>
</div>
