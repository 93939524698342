<div class="wrapper">
    <div class="sidebar">
      <app-sidebar></app-sidebar>
    </div>
  
    <div class="main-panel">
      <app-header [title]="pageTitle"></app-header>
      <div class="content">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
    </div>
  
    <!-- footer component-->
  </div>
  