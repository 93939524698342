<h2 mat-dialog-title class="dialog-title">Add New Department</h2>

<mat-dialog-content>
  <div class="form-container">
    <form [formGroup]="departmentForm">
      <mat-form-field appearance="fill" class="form-field">
        <mat-label>Department Code</mat-label>
        <input matInput placeholder="Department Code" formControlName="departmentCode" required>
        <mat-error *ngIf="departmentForm.controls['departmentCode'].hasError('required')">
          Department Code is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="form-field">
        <mat-label>Department Title</mat-label>
        <input matInput placeholder="Department Title" formControlName="departmentTitle" required>
        <mat-error *ngIf="departmentForm.controls['departmentTitle'].hasError('required')">
          Department Title is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="form-field">
        <mat-label>Email Receiver</mat-label>
        <mat-select formControlName="emailReceiver" required>
          <mat-option *ngFor="let option of options" [value]="option.userId">{{ option.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="departmentForm.controls['emailReceiver'].hasError('required')">
          Email Receiver is required
        </mat-error>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" class="action-button">Cancel</button>
  <button mat-raised-button color="primary" (click)="addDepartment()" class="action-button">Add</button>
</mat-dialog-actions>
