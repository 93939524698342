import { Component, ViewChild, OnInit } from '@angular/core';
import { ArchiveService } from './archive.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentDetailsModalComponent } from './document-details-modal/document-details-modal.component';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.css'],
})
export class ArchiveComponent implements OnInit {
  @ViewChild('paginatorArchives', { static: true }) paginatorArchives!: MatPaginator;
  @ViewChild(MatSort) sortArchives!: MatSort;

  displayedColumnsArchives: string[] = ['documentNumber', 'type', 'userNameSender', 'userName', 'subject', 'timestamp'];
  dataSourceArchives: MatTableDataSource<any> = new MatTableDataSource();
  
  selectedMonth: string | null = this.getCurrentMonth();
  selectedYear: number = new Date().getFullYear();
  years: number[] = [];
  isLoading: boolean = false;

  // Arrays
  finalArchivesArray: any[] = [];
  months = [
    { value: '01', viewValue: 'January' },
    { value: '02', viewValue: 'February' },
    { value: '03', viewValue: 'March' },
    { value: '04', viewValue: 'April' },
    { value: '05', viewValue: 'May' },
    { value: '06', viewValue: 'June' },
    { value: '07', viewValue: 'July' },
    { value: '08', viewValue: 'August' },
    { value: '09', viewValue: 'September' },
    { value: '10', viewValue: 'October' },
    { value: '11', viewValue: 'November' },
    { value: '12', viewValue: 'December' },
  ];

  constructor(
    private archiveService: ArchiveService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.initializeYears();
    this.getCurrentMonth();
  }

  ngOnInit() {
    this.fetchArchive(this.selectedYear.toString());
  }

  // Initialize the years array for the dropdown
  private initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      this.years.push(currentYear - i);
    }
  }

  // Fetch archives for the selected year
  fetchArchive(year: string): void {
    this.isLoading = true; // Set loading state
    forkJoin({
      archives: this.archiveService.getArchives(year).pipe(
        catchError((error) => {
          this.snackBar.open("Error fetching archive list", "Close", {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top'
          });
          return of([]);
        })
      )
    }).subscribe({
      next: ({ archives }) => {
        this.finalArchivesArray = archives;
        this.dataSourceArchives.data = this.finalArchivesArray;
        this.dataSourceArchives.paginator = this.paginatorArchives;
        this.dataSourceArchives.sort = this.sortArchives;
        this.isLoading = false; // Reset loading state
      },
      error: (err) => {
        console.error("Error fetching data", err);
        this.isLoading = false; // Reset loading state on error
      }
    });
  }

  private getCurrentMonth(): string {
    const month = new Date().getMonth() + 1; // getMonth() returns 0-11
    return month < 10 ? '0' + month : month.toString(); // format as '01', '02', etc.
  }

  // Update archives based on selected year
  updateArchives(): void {
    this.fetchArchive(this.selectedYear.toString());
  }

  // Filter archives based on selected month and year
  filterData(): void {
    this.dataSourceArchives.data = this.finalArchivesArray.filter(archive => {
      const date = new Date(archive.timestamp);
      return (!this.selectedMonth || (date.getMonth() + 1 === parseInt(this.selectedMonth))) &&
             (!this.selectedYear || (date.getFullYear() === this.selectedYear));
    });
  }

  // Handle month selection
  onMonthChange(month: string): void {
    this.selectedMonth = month;
    this.filterData(); // Reapply filtering
  }

  // Handle year selection
  onYearChange(year: number): void {
    this.selectedYear = year;
    this.updateArchives(); // Fetch new data based on the selected year
  }

  // Apply filter for the MatTable
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceArchives.filter = filterValue.trim().toLowerCase();
  }

  // Open document details dialog
  openDialog(document: any): void {
    this.dialog.open(DocumentDetailsModalComponent, {
      width: '80vw',
      maxWidth: '95vw',
      maxHeight: '100vh',
      height: '80vh',
      data: document
    });
  }
}
