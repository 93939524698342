<div class="sidebar" *ngIf = isLoaded>
    <div class="sidebar-header">
      <img src="../assets/CHMSULogo.jpg" alt="CHMSU Logo">
    </div>
    <ul class="nav">
      <li class="nav-item">
        <a routerLink="/dashboard/notificationInformation" routerLinkActive="active">
          <i class="material-icons">notifications</i>
          <span>Notifications</span>
        </a>
      </li>
       <li class="nav-item">
        <a routerLink="/dashboard/dashboard-info" routerLinkActive="active">
          <i class="material-icons">dashboard</i>
          <span>Dashboard</span>
        </a>
      </li> 
      <li class="nav-item">
        <a routerLink="/dashboard/archives" routerLinkActive="active">
          <i class="material-icons">archive</i>
          <span>Archive</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="adminDetails">
        <a routerLink="/dashboard/new-document" routerLinkActive="active">
          <i class="material-icons">description</i>
          <span>New Document</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="adminDetails">
        <a routerLink="/dashboard/institutions" routerLinkActive="active">
          <i class="material-icons">business</i>
          <span>Institution</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="adminDetails">
        <a routerLink="/dashboard/reports" routerLinkActive="active">
          <i class="material-icons">bar_chart</i>
          <span>Reports</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="adminDetails">
        <a routerLink="/dashboard/logs" routerLinkActive="active">
          <i class="material-icons">settings_ethernet</i>
          <span>System Logs</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="adminDetails">
        <a routerLink="/dashboard/customgroup" routerLinkActive="active">
          <i class="material-icons">group</i>
          <span>Create New Group</span>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a routerLink="/dashboard/creds" routerLinkActive="active">
          <i class="material-icons">vpn_key</i>
          <span>Credentials</span>
        </a>
      </li> -->
      <li class="nav-item">
        <a routerLink="/logout" routerLinkActive="active">
          <i class="material-icons">exit_to_app</i>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </div>
  