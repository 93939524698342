<div class="wrapper">
    <mat-tab-group>
      <mat-tab label="Institutions">
        <div class="table-container">
          <h2 class="header-title">List of Departments</h2>
  
          <div class="the-table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="departmentCode">
                <th mat-header-cell *matHeaderCellDef>Department Code</th>
                <td mat-cell *matCellDef="let department">
                  {{ department.departmentCode }}
                </td>
              </ng-container>
              <ng-container matColumnDef="departmentTitle">
                <th mat-header-cell *matHeaderCellDef>Department Title</th>
                <td mat-cell *matCellDef="let department">
                  {{ department.departmentTitle }}
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let department">
                  <a (click)="openDialogEdit(department)" class="action-link"
                    >Edit</a
                  >
                  <a
                    (click)="deleteInstitution(department.departmentId)"
                    class="action-link"
                    >Delete</a
                  >
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
  
          <div class="controls">
            <button
              mat-raised-button
              color="primary"
              (click)="openDialog()"
              class="add-button"
            >
              Add New Department
            </button>
  
            <mat-paginator
              [pageSizeOptions]="[5, 10, 20]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Recipients">
        <app-recipients></app-recipients>
      </mat-tab>
    </mat-tab-group>
  </div>
  