<div class="wrapper">
    <div class="container">
      <mat-tab-group>
        <mat-tab label="Individual">
          <app-email-individual></app-email-individual>
        </mat-tab>
        <mat-tab label="Multiple">
          <app-email-multiple></app-email-multiple>
        </mat-tab>
        <mat-tab label="Group">
          <app-email-group></app-email-group>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  