<div class="table-container">
    <h2 class="header-title">List of Users</h2>
  
    <div class="the-table-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let user">{{ user.name }}</td>
        </ng-container>
        <!-- Designation Column -->
        <ng-container matColumnDef="designation">
          <th mat-header-cell *matHeaderCellDef>Designation</th>
          <td mat-cell *matCellDef="let user">
            {{ user.designation || "No Designation" }}
          </td>
        </ng-container>
        <!-- Department Column -->
        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef>Department</th>
          <td mat-cell *matCellDef="let user">
            {{ user.departmentName || "No Department" }}
          </td>
        </ng-container>
        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let user">{{ user.email || "No Email" }}</td>
        </ng-container>
        <ng-container matColumnDef="campus">
          <th mat-header-cell *matHeaderCellDef>Campus</th>
          <td mat-cell *matCellDef="let user">
            <!-- Check the campus value and display the corresponding name, otherwise display the actual value -->
            <ng-container [ngSwitch]="user.campus">
              <ng-container *ngSwitchCase="4">Talisay</ng-container>
              <ng-container *ngSwitchCase="3">Fortune Towne</ng-container>
              <ng-container *ngSwitchCase="2">Binalbagan</ng-container>
              <ng-container *ngSwitchCase="1">Alijis</ng-container>
              <ng-container *ngSwitchDefault>{{ user.campus }}</ng-container>
            </ng-container>
          </td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let user">
            <a (click)="openDialogEdit(user)" class="action-link">Edit</a>
            <a (click)="deleteUser(user.userId)" class="action-link">Delete</a>
          </td>
        </ng-container>
        <!-- Table Header and Rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  
    <div class="controls">
      <button
        mat-raised-button
        (click)="openDialog()"
        color="primary"
        class="add-button"
      >
        Add new recipient
      </button>
  
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
  