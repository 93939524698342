<form [formGroup]="recipientForm" (ngSubmit)="addNewUser()">
    <mat-card class="form-card">
      <mat-card-header>
        <mat-card-title>Recipient Form</mat-card-title>
      </mat-card-header>
  
      <mat-card-content>
        <div class="form-fields">
          <div class="row">
            <mat-form-field appearance="fill" class="col">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" />
              <mat-error *ngIf="recipientForm.get('name')?.hasError('required')">
                Name is required.
              </mat-error>
            </mat-form-field>
          </div>
  
          <div class="row">
            <mat-form-field appearance="fill" class="col">
              <mat-label>Designation</mat-label>
              <input matInput formControlName="designation" />
              <mat-error *ngIf="recipientForm.get('designation')?.hasError('required')">
                Designation is required.
              </mat-error>
            </mat-form-field>
          </div>
  
         <div class="row">
          <mat-form-field appearance="fill" class="col">
            <mat-label>Department</mat-label>
            <mat-select formControlName="departmentId" placeholder="Select a department" >
              <mat-option *ngFor="let department of departments" [value]="department.departmentId">
                {{ department.departmentTitle }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="recipientForm.get('departmentId')?.hasError('required')">
              Department is required.
            </mat-error>
          </mat-form-field>
         </div>
  
          <div class="row">
            <mat-form-field appearance="fill" class="col">
              <mat-label>Campus</mat-label>
              <mat-select formControlName = "campus" placeholder="Select a campus">
                <mat-option [value]=1> Alijis </mat-option>
                <mat-option [value]=4> Talisay </mat-option>
                <mat-option [value]=2> Binalbagan </mat-option>
                <mat-option [value]=3> Fortune Towne </mat-option>
              </mat-select>
              <mat-error *ngIf="recipientForm.get('campus')?.hasError('required')">
                Campus is required.
              </mat-error>
            </mat-form-field>
          </div>
  
          <div class="row">
            <mat-form-field appearance="fill" class="col">
              <mat-label>Company Name</mat-label>
              <input matInput formControlName="companyName" />
              <mat-error *ngIf="recipientForm.get('companyName')?.hasError('required')">
                Company Name is required.
              </mat-error>
            </mat-form-field>
          </div>
  
          <div class="row">
            <mat-form-field appearance="fill" class="col">
              <mat-label>Access Level</mat-label>
              <input matInput formControlName="accessLevel" type="number" />
              <mat-error *ngIf="recipientForm.get('accessLevel')?.hasError('required')">
                Access Level is required.
              </mat-error>
            </mat-form-field>
          </div>
  
          <div class="row">
            <mat-form-field appearance="fill" class="col">
              <mat-label>Employee Type</mat-label>
             <mat-select formControlName = "employeeType" placeholder="Select an employee Type">
              <mat-option value="faculty">Faculty</mat-option>
              <mat-option value="part-time">Part-Time</mat-option>
              <mat-option value="staff">Staff</mat-option>
              <mat-option value="job-order">Job-order</mat-option>
             </mat-select>
              <mat-error *ngIf="recipientForm.get('employeeType')?.hasError('required')">
                Employee Type is required.
              </mat-error>
            </mat-form-field>
          </div>
  
          <div class="row">
            <mat-form-field appearance="fill" class="col">
              <mat-label>Permanence</mat-label>
              <mat-select formControlName = "permanent" placeholder="Select an if permanent">
                <mat-option value=1>Permanent</mat-option>
                <mat-option value=0>Not Permanent</mat-option>
              </mat-select>
              <mat-error *ngIf="recipientForm.get('permanent')?.hasError('required')">
                Permanence is required.
              </mat-error>
            </mat-form-field>
          </div>
  
          <div class="row">
            <mat-form-field appearance="fill" class="col">
              <mat-label>User Type</mat-label>
              <mat-select formControlName="userType" placeholder="Select an user type">
                <mat-option value=1>Permanent User</mat-option>
                <mat-option value=2>External User</mat-option>
              </mat-select>
              <mat-error *ngIf="recipientForm.get('userType')?.hasError('required')">
                User Type is required.
              </mat-error>
            </mat-form-field>
          </div>
  
          <!-- <div class="row">
            <mat-form-field appearance="fill" class="col">
              <mat-label>Email Receiver</mat-label>
              <input matInput formControlName="emailReceiver" type="number" />
              <mat-error *ngIf="recipientForm.get('emailReceiver')?.hasError('required')">
                Email Receiver is required.
              </mat-error>
            </mat-form-field>
          </div> -->
  
          <div class="row">
            <mat-form-field appearance="fill" class="col">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" type="email" />
              <mat-error *ngIf="recipientForm.get('email')?.hasError('required')">
                Email is required.
              </mat-error>
              <mat-error *ngIf="recipientForm.get('email')?.hasError('email')">
                Enter a valid email address.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
  
      <mat-card-actions>
        <button mat-raised-button color="primary" type="submit">Submit</button>
        <button mat-stroked-button type="reset">Reset</button>
      </mat-card-actions>
    </mat-card>
  </form>
  