<mat-card>
    <mat-card-header>
      <mat-card-title>Document Processing Logs</mat-card-title>
    </mat-card-header>
  
    <mat-card-content>
      <!-- Date Picker -->
      <mat-form-field appearance="fill" class="date-picker w-100">
        <mat-label>Select Date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          (dateChange)="onDateChange($event)"
          [value]="currentDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
  
      <!-- Loading Spinner -->
      <ng-container *ngIf="loadingUserLogsList$ | async; else content">
        <div class="loading-container">
          <mat-spinner></mat-spinner>
          <p>Loading logs...</p>
        </div>
      </ng-container>
  
      <!-- Error Message -->
      <ng-template #errorContent>
        <div *ngIf="errorUserLogsList$ | async as error" class="error-message">
          <mat-error>An error occurred: {{ error | json }}</mat-error>
        </div>
      </ng-template>
  
      <!-- Table Content -->
      <ng-template #content>
        <div
          class="the-table-container mt-3"
          *ngIf="!(errorUserLogsList$ | async)"
        >
          <table mat-table [dataSource]="finalLogsArray" class="mat-elevation-z4">
            <!-- Columns Definitions -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let log">
                {{
                  log.timestamp
                    ? (log.timestamp | date : "MMMM dd, yyyy hh:mm:ss aa")
                    : "No Date Available"
                }}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef>System User</th>
              <td mat-cell *matCellDef="let log">{{ log.userName }}</td>
            </ng-container>
  
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let log">{{ log.message }}</td>
            </ng-container>
  
            <!-- Header and Row Definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </ng-template>
    </mat-card-content>
  </mat-card>
  