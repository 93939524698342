<div class="wrapper">
  <div class="container mat-elevation-z8-container">
    <!-- Check if dataSourceNotifications is null or empty -->
    <div *ngIf="!dataSourceNotifications || dataSourceNotifications.data.length === 0" class="no-notifications">
      No Notifications
    </div>

    <mat-table *ngIf="dataSourceNotifications && dataSourceNotifications.data.length > 0" [dataSource]="dataSourceNotifications" class="mat-elevation-z8">
      <!-- Document Number Column -->
      <ng-container matColumnDef="documentNumber">
        <mat-header-cell *matHeaderCellDef> Document Number </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a (click)="openModal(element)" class="document-link">
            {{ element.documentNumber }}
          </a>
        </mat-cell>
      </ng-container>

      <!-- Receivers Column -->
      <ng-container matColumnDef="receivers">
        <mat-header-cell *matHeaderCellDef> Receivers </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.receiver }} </mat-cell>
      </ng-container>

      <!-- Sender Column -->
      <ng-container matColumnDef="sender">
        <mat-header-cell *matHeaderCellDef> Sender </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.sender }} </mat-cell>
      </ng-container>

      <!-- Timestamp Column -->
      <ng-container matColumnDef="timestamp">
        <mat-header-cell *matHeaderCellDef> Timestamp </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.timestamp | date : "short" }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <!-- Paginator -->
    <mat-paginator *ngIf="dataSourceNotifications && dataSourceNotifications.data.length > 0" [pageSizeOptions]="[15, 30, 45]" showFirstLastButtons></mat-paginator>

    <!-- Receiver Modal Template -->
    <ng-template #receiverModal let-data="data">
      <h2>Receivers for {{ data.documentNumber }}</h2>
      <table mat-table [dataSource]="data.receivers" class="mat-elevation-z8">
        <!-- Receiver Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let receiver"> {{ receiver.name }} </mat-cell>
        </ng-container>

        <!-- Viewed Status Column -->
        <ng-container matColumnDef="viewed">
          <mat-header-cell *matHeaderCellDef> Viewed? </mat-header-cell>
          <mat-cell *matCellDef="let receiver">
            {{ receiver.viewed ? "Yes" : "No" }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="['name', 'viewed']"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['name', 'viewed']"></mat-row>
      </table>
    </ng-template>
  </div>
</div>
