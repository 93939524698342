import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { TokenService } from 'src/app/token.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {
  private apiBaseUrl = environment.apiBaseUrl;
  constructor(private http:HttpClient, private tokenService:TokenService) { }

  getArchives(currentYear: string): Observable<any[]> {
    return this.tokenService.getToken().pipe(
      switchMap((response: any) => {
        const token = response.accessToken; // Replace with your actual token
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
  
        // Properly add query parameters using HttpParams
        const params = new HttpParams().set('currentYear', currentYear);
  
        return this.http.get<any[]>(`${this.apiBaseUrl}/archives/documents`, {
          headers: headers,
          params: params,
          withCredentials: true
        });
      }),
      map((response: any[]) => response || []), // Return response or an empty array if null/undefined
      catchError((error) => {
        console.error('Failed to fetch archives', error);
        return of([]); // Return an empty array on error
      })
    );
  }
  

  

  public getAdminDetails(){
    return this.http.get(`${this.apiBaseUrl}/user/details`,{responseType:'text',withCredentials:true}).pipe(
      map((response:any)=>{
        try{
          return JSON.parse(response);
        }catch(e){
          console.error('Failed to parse response',e)
          return [];
        }
      })
    );
   }
}
