export const environment = {
  production: false,
  apiBaseUrl:"https://heis-test-backend.chmsu.edu.ph",
  // apiBaseUrl:'https://heis-test-backend.chmsu.edu.ph',
  emailConfig: {
    primaryUsername: 'johnkevin.moraca@chmsu.edu.ph',
    primaryPassword: 'pbxa jpbh ojzn yjke',
    secondaryUsername: 'jkmoraca.work@gmail.com',
    secondaryPassword: 'imja bppr qkdl zjxe',
    usageLimit: 100 // Example usage limit
  },
    secretKey: 'chmsu.edu.ph.secret-key.secret'
};
