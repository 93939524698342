<mat-card class="login-card">
  <mat-card-header>
    <img src="../assets/CHMSULogo.jpg" alt="CHMSU Logo" />
    <h1>CARLOS HILADO<br /><span>MEMORIAL STATE UNIVERSITY</span></h1>
    <h2>Records Management System</h2>
  </mat-card-header>

  <mat-card-content>
    <!-- Additional Links -->
    <div class="additional-links">
      <h1>Sign-In</h1>
      <p>Use your CHMSU Google Account</p>
      <button mat-raised-button class="google-btn" (click)="onLoginButtonClick()">
        <img
          src="https://developers.google.com/identity/images/g-logo.png"
          alt="Google Logo"
          class="google-logo"
        />
        Sign in with Google
      </button>
    </div>
  </mat-card-content>
</mat-card>
