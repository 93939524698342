import { Component, Inject, OnInit } from '@angular/core';
import { DocumentModalService } from './document-modal.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-details-modal',
  templateUrl: './document-details-modal.component.html',
  styleUrls: ['./document-details-modal.component.css']
})
export class DocumentDetailsModalComponent {
  imageUrls: string[] = [];
  userNames: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DocumentDetailsModalComponent>,
    private modalService: DocumentModalService
  ) {}

  ngOnInit(): void {
   
    //////console.log(this.data.attention);
    if (this.data.attachment) {
      const filenames = this.modalService.parseFilenames(this.data.attachment);
      this.loadFiles(filenames);
    }
  }

  loadFiles(filenames: string[]) {
    this.modalService.generateUrls(filenames).forEach(fileObservable => {
      fileObservable.subscribe(blob => {
        // Convert the blob into a URL and store it in the array
        const objectUrl = URL.createObjectURL(blob);
        this.imageUrls.push(objectUrl);
        //console.log(this.imageUrls)
      }, error => {
        console.error('Error fetching file:', error);
      });
    });
  }

  printImages() {
    const printContent = document.getElementById('printableArea');
    const printWindow = window.open('', '_blank', 'width=800,height=600');
  
    const currentDate = new Date().toLocaleDateString(); // Get the current date
  
    printWindow?.document.write(`
      <html>
        <head>
          <title>Print Images</title>
          <style>
            @media print {
              @page {
                margin: 20mm; /* Standard margin for printed pages */
              }
  
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                min-height: 100vh;
              }
  
              .image-card {
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(100vh - 60px); /* Adjust for footer */
                padding: 20px;
                box-sizing: border-box;
              }
  
              #card-image {
                max-width: 100%;
                max-height: 100%;
                height: auto;
                width: auto;
                margin: auto;
              }
  
              /* Footer Styling */
              .footer {
                position: fixed;
                bottom: 0;
                left: -20;
                width: 100%;
                padding: 10px;
                font-size: 12px;
                border-top: 1px solid #ccc;
                background: white;
                text-align: right
              }
  
              /* Ensures content is properly spaced on every page */
              .page-content {
                break-inside: avoid; /* Avoid splitting content across pages */
              }
            }
          </style>
        </head>
        <body onload="window.print()">
          <div class="page-content">${printContent?.innerHTML}</div>
          
          <div class="footer">
            <div>Generated by Records Management System:</div>
            <div>Date Generated: ${currentDate}</div>
          </div>
        </body>
      </html>
    `);
  
    printWindow?.document.close();
  }

  // Optionally, you can release the created URL objects when no longer needed to avoid memory leaks
  ngOnDestroy() {
    this.imageUrls.forEach(url => URL.revokeObjectURL(url));
  }

  getLetterType(type: number): string {
    switch (type) {
      case 1: return 'Memo';
      case 2: return 'Letter';
      case 3: return 'Special Orders';
      case 4: return "President's Memo";
      case 5: return 'Other Memos';
      default: return 'Unknown';
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
  
  
}

