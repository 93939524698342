<div class="header">
    <div class="logo">
        <img src="../assets/CHMSULogo.jpg" alt="Logo">
    </div>
    <div class="title">
        {{title}}
    </div>
    <div class="details">
        <p class="doc-code">Document Code: F.05-RO-CHMSU</p>
        <p class="rev-no">Revision No.: 0</p>
        <p class="effective-date">Effective Date: May 6, 2024</p>
        <p class="page"></p>
    </div>
</div>
